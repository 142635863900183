import React from 'react';
import styled from 'styled-components';
import badkamer from '../assets/images/fotos pagina/badkamer.jpg';
import bed from '../assets/images/fotos pagina/bed.jpg';
import buiten from '../assets/images/fotos pagina/buiten.jpg';
import hethuis from '../assets/images/fotos pagina/hethuis.jpg';
import woonkamer from '../assets/images/fotos pagina/woonkamer.jpg';
import woonkamerraam from '../assets/images/fotos pagina/woonkameraam.jpg';
import woonkameroverzicht from '../assets/images/fotos pagina/woonkameroverzicht.jpg';
import winter1 from '../assets/images/fotos pagina/winter1.jpg';
import winter2 from '../assets/images/fotos pagina/winter2.jpg';
import winter3 from '../assets/images/fotos pagina/winter3.jpg';
import winter4 from '../assets/images/fotos pagina/winter4.jpg';
import winter5 from '../assets/images/fotos pagina/winter5.jpg';
import Layout from '../components/Layout';

const Bodystyles = styled.div`
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 400px);
    grid-template-rows: 1fr;
    justify-items: center;
    justify-content: center;
  }

  .titel {
    text-align: center;
    justify-content: center;
    font-weight: 700;
    font-size: 70px;
    color: #14284e;
    margin-top: 150px;
  }

  .main {
    margin-bottom: 50px;
  }

  .row {
    display: flex;
    justify-content: center;
  }

  img {
    width: 360px;
    height: 285px;
    margin-bottom: 30px;
  }

  @media (max-width: 1200px) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 400px);
      grid-template-rows: 1fr;
      justify-items: center;
    }
  }

  @media (max-width: 797px) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(1, 400px);
      grid-template-rows: 1fr;
      justify-items: center;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font-size: 50px !important;
      margin-bottom: 50px;
    }
    img {
      width: 75%;
      height: 200px;
    }
    .grid-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`;

const Fotos = () => (
  <Layout>
    <Bodystyles>
      <div className="main">
        <h1 className="titel">Foto&apos;s</h1>
        <div className="grid-container">
          <img draggable="false" alt="" src={badkamer} />
          <img draggable="false" alt="" src={bed} />
          <img draggable="false" alt="" src={buiten} />
          <img draggable="false" alt="" src={hethuis} />
          <img draggable="false" alt="" src={woonkamer} />
          <img draggable="false" alt="" src={woonkamerraam} />
          <img draggable="false" alt="" src={woonkameroverzicht} />
          <img draggable="false" alt="" src={winter1} />
          <img draggable="false" alt="" src={winter2} />
          <img draggable="false" alt="" src={winter3} />
          <img draggable="false" alt="" src={winter4} />
          <img draggable="false" alt="" src={winter5} />
        </div>
      </div>
    </Bodystyles>
  </Layout>
);

export default Fotos;
